import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { AuthKitProvider } from "@farcaster/auth-kit";
import { SignInButton } from "@farcaster/auth-kit";
import "@farcaster/auth-kit/styles.css";
import { useDisconnect } from "@web3modal/ethers/react";

import {
  createAccount,
  getTwitterLoginUrl,
  fcRegister,
  setReferral,
} from "../../../api/API";
import useUpdateMyInfo from "../../../hooks/useUpdateMyInfo";
import { closeModal, openModal } from "../../../redux/modalSlice";
import { updateMyInfo, updateWallet } from "../../../redux/userSlice";
import contents from "../../../utils/contents";
import { WARPCAST_PROFILE_URL, X_PROFILE_URL } from "../../../utils/urls";
import { getSlicedAddress, trackAmplitudeEvent } from "../../../utils/utils";

const config = {
  rpcUrl: "https://mainnet.optimism.io",
  domain: "plur.xyz",
  siweUri: "https://plur.xyz",
};

function AccountModal() {
  const [code, setCode] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { disconnect } = useDisconnect();
  const myInfo = useSelector((state) => state.user.myInfo);
  const wallet = useSelector((state) => state.user.wallet);
  const { setMyInfo } = useUpdateMyInfo();
  const twitterProfile = myInfo?.user?.twitterProfile;
  const fcProfile = myInfo?.user?.fcProfile;

  useEffect(() => {
    let storedReferralCode = localStorage.getItem("referral");
    if (storedReferralCode) {
      setCode(storedReferralCode);
    }
  }, []);

  const fcCallback = useCallback(
    async (data) => {
      trackAmplitudeEvent(`${contents.amp.connectFarcaster}`);
      try {
        if (!myInfo?.user?.ID) {
          await createAccount(wallet);
        }
        await fcRegister(
          {
            message: data.message,
            signature: data.signature,
            fid: `${data.fid}`,
          },
          wallet,
        );
        await setMyInfo();
      } catch (e) {
        toast.error(
          <div>
            Farcaster login failed
            <br />
            <span className="text-sm">Please try again later</span>
          </div>,
        );
      }
    },
    [myInfo?.user?.ID],
  );

  return (
    <div className="flex min-w-96 flex-col gap-4 bg-white p-6 font-dec sm:min-w-72">
      <h1 className="font-display text-lg tracking-wider text-indigo-600">
        Account
      </h1>
      <h2 className="font-dec font-medium before:mr-2 before:text-emerald-300 before:content-['✦']">
        Wallet
      </h2>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <span className="text-sm">{getSlicedAddress(wallet.address)}</span>
        </div>
        <button
          className=" rounded border border-solid border-emerald-300 px-4 py-2 text-sm text-emerald-400 hover:border-emerald-500 hover:text-emerald-600 "
          onClick={() => {
            trackAmplitudeEvent(`${contents.amp.disconnectWallet}`);
            dispatch(closeModal());
            dispatch(updateWallet(null));
            dispatch(updateMyInfo(null));
            localStorage.removeItem("wallet");
            localStorage.removeItem("signatureV2");
            localStorage.removeItem("referral");
            disconnect();
            navigate("/");
          }}
        >
          disconnect
        </button>
      </div>
      <h2 className="font-dec font-medium before:mr-2 before:text-emerald-300 before:content-['✦']">
        X (Twitter)
      </h2>
      {twitterProfile ? (
        <div
          className="flex cursor-pointer items-center gap-2 sm:overflow-x-hidden xs:max-w-60"
          onClick={() =>
            twitterProfile.UserName &&
            window.open(`${X_PROFILE_URL}/${twitterProfile.UserName}`)
          }
        >
          <img
            src={twitterProfile.Picture}
            alt="pfp"
            className="h-6 w-6 rounded-full bg-orange-400"
          />
          <span className="truncate text-sm">{twitterProfile.Name}</span>
          <span className="truncate text-xs text-slate-700">
            {twitterProfile.UserName ? `@${twitterProfile.UserName}` : ""}
          </span>
        </div>
      ) : (
        <button
          className="rounded bg-emerald-400 px-4 py-2 text-sm font-medium text-slate-50 enabled:hover:bg-emerald-500 disabled:opacity-25"
          onClick={async () => {
            trackAmplitudeEvent(`${contents.amp.connectX}`);
            let inviteCode;
            if (!myInfo) {
              let { inviteCode: newInviteCode } = await createAccount(wallet);
              if (!newInviteCode) {
                dispatch(
                  openModal(
                    <div className="p-6">
                      Something went wrong when creating account
                    </div>,
                  ),
                );
                return;
              }
              const returnedMyInfo = await setMyInfo();
              inviteCode = returnedMyInfo.register_code.Code;
            } else {
              inviteCode = myInfo.register_code.Code;
            }
            const twitterUrl = getTwitterLoginUrl(wallet.address, inviteCode);
            window.open(twitterUrl, "_self");
          }}
        >
          Connect with X
        </button>
      )}

      <AuthKitProvider config={config}>
        <h2 className="font-dec font-medium before:mr-2 before:text-emerald-300 before:content-['✦']">
          Farcaster
        </h2>
        {fcProfile ? (
          <div
            className="flex cursor-pointer items-center gap-2 sm:overflow-x-hidden xs:max-w-60"
            onClick={(e) => {
              e.stopPropagation();
              window.open(`${WARPCAST_PROFILE_URL}/${fcProfile.UserName}`);
            }}
          >
            <img
              src={fcProfile.Picture}
              alt="pfp"
              className="h-6 w-6 rounded-full bg-orange-400"
            />
            <span className="truncate text-sm">{fcProfile.Name}</span>
            <span className="truncate text-xs text-slate-700">
              {fcProfile.UserName ? `@${fcProfile.UserName}` : ""}
            </span>
          </div>
        ) : (
          <SignInButton
            // nonce={getNonce}
            onSuccess={fcCallback}
            // onError={() => setError(true)}
            // onSignOut={() => signOut() }
          />
        )}
      </AuthKitProvider>
      {myInfo?.user?.SocialProfiles.length > 0 && (
        <>
          <h2 className="font-dec font-medium before:mr-2 before:text-emerald-300 before:content-['✦']">
            Your Referral Code
          </h2>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <span className="text-sm">{myInfo.invite_codes[0].Code}</span>
            </div>
            <button
              className=" rounded border border-solid border-emerald-300 px-4 py-2 text-sm text-emerald-400 hover:border-emerald-500 hover:text-emerald-600 "
              onClick={() => {
                toast.success("Referral code copied!");
                navigator.clipboard.writeText(
                  `${window.location.origin}?referral=${myInfo.invite_codes[0].Code}`,
                );
              }}
            >
              share
            </button>
          </div>
          {myInfo.referral?.ReferralCode?.User ? (
            <>
              <h2 className="font-dec font-medium before:mr-2 before:text-emerald-300 before:content-['✦']">
                Referrer
              </h2>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <span className="text-sm">
                    {myInfo.referral?.ReferralCode?.User?.UserName || myInfo.referral?.ReferralCode?.User?.name}
                  </span>
                </div>
              </div>
            </>
          ) : (
            <>
              <h2 className="font-dec font-medium before:mr-2 before:text-emerald-300 before:content-['✦']">
                Set Referrer
              </h2>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <input
                    className="border-1 border border-slate-300 p-1 text-sm"
                    value={code}
                    placeholder="Enter referral code"
                    onChange={(e) => {
                      setCode(e.target.value.toUpperCase());
                    }}
                  />
                </div>
                <button
                  className="rounded border border-solid border-emerald-300 px-4 py-2 text-sm text-emerald-400 hover:border-emerald-500 hover:text-emerald-600 disabled:opacity-25"
                  disabled={isLoading}
                  onClick={async () => {
                    setIsLoading(true);
                    try {
                      await setReferral(wallet, myInfo.user.ID, code);
                      await setMyInfo();
                    } catch (e) {
                      if (e.message.includes("cannot add self")) {
                        toast.error("Can not set self as referrer");
                      } else if (
                        e.message.includes("referral code record not found")
                      ) {
                        toast.error("Invalid referral code");
                      } else {
                        toast.error("Set referrer failed");
                      }
                    }
                    setIsLoading(false);
                  }}
                >
                  set
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default AccountModal;
