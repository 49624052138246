import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { WarningCircle, X } from "@phosphor-icons/react";

import { closeModal, openModal } from "../../redux/modalSlice";
import { closeMainnetBanner } from "../../redux/paramsSlice";
import { PLUR_TESTNET_URL } from "../../utils/urls";

function MainnetBanner() {
  const dispatch = useDispatch();
  const isMainnetBannerShown = useSelector(
    (state) => state.params.isMainnetBannerShown,
  );

  const endOfServiceModal = () => (
    <div className="no-scrollbar relative max-h-svh w-[500px] p-6 text-center sm:h-svh sm:w-screen sm:p-4">
      <div className="no-scrollbar flex h-full w-full flex-col items-center justify-start gap-2 overflow-y-scroll">
        <X
          className="absolute right-4 top-4 h-4 w-4 cursor-pointer text-gray-500"
          onClick={() => {
            dispatch(closeModal());
          }}
        />
        <WarningCircle className="h-12 w-12 shrink-0 text-red-500" />
        <h1 className="text-2xl font-semibold">End of Service Announcement</h1>
        <p className="flex flex-col gap-y-2 text-left">
          <span>Dear Plur Users,</span>
          <span>
            After careful consideration by our team, we have made the decision
            to discontinue our product.
          </span>
          <span>
            We plan to shut down the Plur product on
            <span className="font-bold"> May 31, 2024</span>, so please withdraw
            your assets from Plur as soon as possible. Starting now, the product
            will enter maintenance mode, during which functionalities such as
            Deposit / Create Pool will be disabled, and distribution of Plur
            point will stop.
          </span>
          <span>
            All Blast Gold will be distributed to Plur users based on the Plur
            Points accumulated, excluding the pool created by Plur. Gold
            distribution will occur within this week.
          </span>
          <span>
            We apologize for any inconvenience this may cause and thank you for
            your participation and support during this time.
          </span>
          Sincerely, <br />
          Plur Team
        </p>
      </div>
    </div>
  );

  useEffect(() => {
    dispatch(openModal(endOfServiceModal()));
  }, []);

  if (!isMainnetBannerShown) return null;
  return (
    <div
      className="w-full cursor-pointer bg-red-600 py-1 text-center font-inter text-sm text-white"
      onClick={() => {
        dispatch(openModal(endOfServiceModal()));
      }}
    >
      <WarningCircle
        className="mb-1 mr-1 inline-block h-4 w-4 text-white"
        weight="bold"
      />
      <span className="font-medium">
        Plur will be shut down on May 31, 2024.
      </span>{" "}
      Please withdraw you assets as soon as possible.
    </div>
  );
  // return (
  //   <div className="flex w-full flex-wrap items-center justify-center gap-x-4 px-6 py-2 font-inter sm:py-3 xs:px-0 xs:py-1">
  //     <div className="text-sm font-medium text-indigo-600 ">
  //       Plur is live on{" "}
  //       <a
  //         href="https://blast.io/en"
  //         className="font-bold italic hover:underline"
  //       >
  //         BLAST
  //       </a>{" "}
  //       mainnet!
  //     </div>
  //     <div className="text-center text-xs text-gray-500">
  //       Access the testnet version{" "}
  //       <a
  //         href={PLUR_TESTNET_URL}
  //         className="cursor-pointer underline underline-offset-1 hover:text-gray-600"
  //       >
  //         here
  //       </a>
  //       .
  //     </div>
  //     <X
  //       className="absolute right-6 cursor-pointer text-gray-600 md:right-2 sm:right-1 sm:top-1"
  //       onClick={() => dispatch(closeMainnetBanner())}
  //     />
  //   </div>
  // );
}

export default MainnetBanner;
